<template>
  <div style="margin: 0 auto; max-width: 500px;padding: 10px;">
    <h3 style="text-align: center;">{{ $t('All.铜线标识卡') }}</h3>
    <el-form ref="elForm" :model="formData" :rules="rules" size="medium" label-width="90px"
      label-position="right">
      <el-form-item :label="$t('All.状态')">
        <el-tag :type="formData.State === 0?'success':'danger'" effect="dark">{{ formData.State === 0?$t('All.有效'): formData.State === 2 ? formData.Order_no + $t('All.正在使用'):$t('All.失效')}}</el-tag>
      </el-form-item>
      <el-form-item :label="$t('All.生产日期')">
        {{ formData.Cdate }}
      </el-form-item>
      <el-form-item :label="$t('All.品名')">
        {{ formData.Product_name }}
      </el-form-item>
      <el-form-item :label="$t('All.规格')">
        {{ formData.Gg }}
      </el-form-item>
      <el-form-item :label="$t('All.料号')">
        {{ formData.Item_no }}
      </el-form-item>
      <el-form-item :label="$t('All.Lot号')">
        {{ formData.Lot }}
      </el-form-item>
      <el-form-item :label="$t('All.工单号')">
        {{ formData.Gd }}
      </el-form-item>
      <el-form-item :label="$t('All.绞距')">
        {{ formData.Jj }}
      </el-form-item>
      <el-form-item :label="$t('All.机台')">
        {{ formData.Jt }}
      </el-form-item>
      <el-form-item :label="$t('All.班别')">
        {{ formData.Bb }}
      </el-form-item>
      <el-form-item :label="$t('All.操作员')">
        {{ formData.Czu }}
      </el-form-item>
      <el-form-item :label="$t('All.总数量')">
        {{ formData.Scsl }}
      </el-form-item>
      <el-form-item :label="$t('All.当前数量')">
        {{formData.DScsl}}
      </el-form-item>
      <el-form-item
        v-for="(domain, index) in Boxlist"
        :label="$t('All.数量')"
        :key="index"
      >
        <el-input v-model="domain.Boxquantity" onkeyup="value=value.replace(/[^\d^\.]/g,'')" oninput="if (value.trim() === '') { value = null; } if (isNaN(value)) { value = null; } if (value.indexOf('.') > 0) { value = value.slice(0, value.indexOf('.') + 5); }" style="width:100px;" autocomplete="off"></el-input>
        <span style="padding: 10px;">{{$t('All.张数')}}:</span><el-input onkeyup="value=value.replace(/[^\d^\.]/g,'')" oninput="if (value.trim() === '') { value = null; } if (isNaN(value)) { value = null; } if (value.indexOf('.') > 0) { value = null; }" v-model="domain.Box" style="width:100px;" autocomplete="off"></el-input><el-button v-show="index + 1 === Boxlist.length" style="margin-left: 11px;" size="mini" type="primary" icon="el-icon-plus"  @click="addDomainbut()"></el-button><el-button v-show="Boxlist.length > 1" size="mini" type="danger" icon="el-icon-delete"  @click="removeDomainbut(domain)"></el-button>
      </el-form-item>
      <el-form-item :label="$t('All.打印机')" v-if="formData.State === 0">
          <el-select :style="{width: '100%'}" v-model="Printname" value-key="Id" :placeholder="$t('All.请选择打印机')">
            <el-option
              v-for="item in this.$store.state.PrinterData"
              :key="item.Name"
              :label="item.Wz"
              :value="item">
          </el-option>
          </el-select>
        </el-form-item>
      <el-form-item style="margin: 0 auto;text-align: center;" size="large" v-if="formData.State === 0">
        <el-button type="primary" @click="submitForm">{{ $t('All.提交') }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  components: {},
  data () {
    return {
      formData: {
        Product_name: '',
        Serialcode: '',
        Gg: '',
        Item_no: '',
        Lot: '',
        Bb: '',
        Scsl: '',
        Cdate: new Date(),
        Jj: '',
        Jt: '',
        Czu: this.$store.state.Login.Username,
        Czuuser: this.$store.state.Login.User,
        Gd: '',
        State: 0,
        Type: 0,
        Fby: '',
        Fbyuser: '',
        Cid: 0,
        Numbers: '',
        Syr: '',
        Syruser: '',
        Region: this.$store.state.Login.Factory
      },
      rules: {
        Product_name: [{
          required: true,
          message: this.$t('All.请输入品名'),
          trigger: 'blur'
        }],
        Gg: [{
          required: true,
          message: this.$t('All.请输入规格'),
          trigger: 'blur'
        }],
        Item_no: [{
          required: true,
          message: this.$t('All.请输入料号'),
          trigger: 'blur'
        }],
        Lot: [{
          required: true,
          message: this.$t('All.请输入Lot号'),
          trigger: 'blur'
        }],
        Gd: [{
          required: true,
          message: this.$t('All.请输入工单号'),
          trigger: 'blur'
        }],
        Jj: [{
          required: true,
          message: this.$t('All.请输入绞距'),
          trigger: 'blur'
        }],
        Jt: [{
          required: true,
          message: this.$t('All.请输入机台'),
          trigger: 'blur'
        }],
        Bb: [{
          required: true,
          message: this.$t('All.请输入班别'),
          trigger: 'blur'
        }],
        Scsl: [{
          required: true,
          message: this.$t('All.请输入生产数量'),
          trigger: 'blur'
        }],
        Cdate: [{
          required: true,
          message: this.$t('All.请选择生产日期'),
          trigger: 'change'
        }]
      },
      Printname: '',
      Coutl: '',
      Boxlist: [
        {
          Box: 1,
          Boxquantity: 1
        }
      ]
    }
  },
  computed: {},
  watch: {},
  props: ['Addtab', 'RemoveTab', 'Renewtargename', 'Rendw'],
  created () {
    this.Subcontractset(this.$store.state.Qcrode)
  },
  mounted () {},
  methods: {
    addDomainbut () {
      var zong = 0
      this.Boxlist.forEach(element => {
        zong = this.$PublicJs.add(zong, this.$PublicJs.mul(element.Box, element.Boxquantity))
      })
      var shengyu = this.$PublicJs.sub(this.formData.DScsl, zong)
      if (shengyu <= 0) return this.$message.error(this.$t('All.数量已不足'))
      this.Boxlist.push({
        Box: 1,
        Boxquantity: shengyu
      })
    },
    removeDomainbut (item) {
      this.$confirm(this.$t('All.是否删除此节点'), this.$t('All.提示'), {
        confirmButtonText: this.$t('All.确认'),
        cancelButtonText: this.$t('All.取消'),
        type: 'warning'
      })
        .then(() => {
          if (this.Boxlist.length === 1) {
            return this.$message.error(this.$t('All.最少保留一列'))
          }
          var index = this.Boxlist.indexOf(item)
          if (index !== -1) {
            this.Boxlist.splice(index, 1)
          }
        })
        .catch(() => {
        })
    },
    submitForm () {
      var zong = 0
      this.Boxlist.forEach(element => {
        zong = this.$PublicJs.add(zong, this.$PublicJs.mul(element.Box, element.Boxquantity))
      })
      if (parseFloat(zong) !== parseFloat(this.formData.DScsl)) return this.$message.error(this.$t('All.分包数量与数量不符合'))
      const loadingInstance = this.$loading({ text: this.$t('All.打印中'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        if (this.Printname.length < 1) {
          loadingInstance.close()
          return this.$message.error(this.$t('All.请选择打印机后在进行打印'))
        }
        this.$refs.elForm.validate(async valid => {
          if (!valid) {
            loadingInstance.close()
            return this.$message.error(this.$t('All.数据填写不完整'))
          }
          var isbool = 0
          this.Boxlist.forEach(element => {
            isbool += parseInt(element.Box)
          })
          if (isbool > 10) {
            loadingInstance.close()
            this.$confirm(`${this.$t('All.确认要打印')}${isbool}${this.$t('All.张')}？`, this.$t('All.提示'), {
              confirmButtonText: this.$t('All.确认'),
              cancelButtonText: this.$t('All.取消'),
              type: 'warning'
            })
              .then(async () => {
                const loadingInstance1 = this.$loading({ text: this.$t('All.打印中'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
                const postdata = {
                  CTClass: this.formData,
                  PrinterData: this.Printname,
                  Boxlist: this.Boxlist,
                  UserData: this.$store.state.Login
                }
                const { data: res } = await this.$http.post('/api/Semifinished/CBCTSeleUserData', postdata)
                if (res.status !== 200) {
                  loadingInstance1.close()
                  return this.$message.error(res.msg)
                }
                loadingInstance1.close()
                this.$message.success(res.msg)
                this.$store.state.TabComponent = 'Qecode'
                this.RemoveTab(this.$t('All.铜线标识卡'))
                this.Addtab(this.$t('All.二维码'), 'Qecode')
              })
              .catch(() => {
              })
            return
          }
          const loadingInstance2 = this.$loading({ text: this.$t('All.打印中'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
          const postdata = {
            CTClass: this.formData,
            PrinterData: this.Printname,
            Boxlist: this.Boxlist,
            UserData: this.$store.state.Login
          }
          const { data: res } = await this.$http.post('/api/Semifinished/CBCTSeleUserData', postdata)
          if (res.status !== 200) {
            loadingInstance2.close()
            return this.$message.error(res.msg)
          }
          loadingInstance2.close()
          this.$message.success(res.msg)
          this.$store.state.TabComponent = 'Qecode'
          this.RemoveTab(this.$t('All.铜线标识卡'))
          this.Addtab(this.$t('All.二维码'), 'Qecode')
        })
      } catch (error) {
        loadingInstance.close()
      }
    },
    resetForm () {
      this.$refs.elForm.resetFields()
    },
    async Subcontractset (row) {
      const { data: res } = await this.$http.get('/api/Semifinished/GetCBCTClass?Serialcode=' + row)
      if (res.status !== 200) return this.$message.error(res.msg)
      this.formData = res.response
      this.formData = { ...this.formData }
      this.$message.success(res.msg)
    }
  }
}

</script>
<style>
</style>
